import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import {themeBright} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import ThemeBox from "../../components/bricks/ThemeBox";

const Features = ({location}) => (
  <DefaultLayout
    title="Milestones & Runs"
    description="Plan for your release – one milestone at a time."
    location={location}
  >
    <Hero size="sm" title="Milestones & Runs" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature
        title="Track your Milestones"
        img="timeline/milestone.png"
        childAsDescription
      >
        Deadlines are key in game development. Stay on top of deliverables with ease. Codecks helps
        you track milestones tied to target dates, ensuring your project moves smoothly and your
        team stays aligned with long-term goals.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Build Team Momentum"
        img="timeline/runs.png"
        imgRight
      >
        Boost your team’s focus with recurring automated Runs. By planning work in weekly, biweekly,
        or monthly cycles, your team builds healthy routines that keep near-term goals clear and
        achievable.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Plan ahead and stay organized"
        img="timeline/timeline.png"
      >
        Whether focusing on the next milestone or run, the timeline gives you a clear view of what’s
        ahead, helping you blend near-term objectives with the bigger picture for smooth, efficient
        planning.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Manage Workload with Precision"
        img="timeline/capacity-tracking.png"
        imgRight
      >
        Keep workloads balanced and productivity high and avoid crunch with the capacity tracker. It
        automatically highlights team members who are overburdened or under-tasked, allowing you to
        adjust plans on the fly for optimal team performance.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Fight the Beasts"
        img="timeline/beast.png"
        titleUrl="/features/beast-mode/"
      >
        Tackle overdue tasks with Beast Levels, a fun way to prioritize work and improve
        estimations. The more a card falls behind, the higher its beast level rises — motivating
        your team to get back on track.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
